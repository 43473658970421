import React, {useEffect} from 'react';
import '../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../Images/gallery/image1.png';
import img2 from '../Images/gallery/image2.jpeg';
import img3 from '../Images/gallery/image3.jpg';
import img4 from '../Images/gallery/image4.jpg';
import img5 from '../Images/gallery/image5.jpg';
import img6 from '../Images/gallery/image6.jpg';
import img7 from '../Images/gallery/image7.jpg';
import img8 from '../Images/gallery/image8.jpeg';
import img9 from '../Images/gallery/image10.jpg';
import img10 from '../Images/gallery/image14.jpg';

import img11 from '../Images/gallery/image15.JPG';
import img12 from '../Images/gallery/image16.JPG';
import img13 from '../Images/gallery/image17.JPG';
import img14 from '../Images/gallery/image18.JPG';
import img15 from '../Images/gallery/image19.JPG';
import img16 from '../Images/gallery/image20.JPG';
import img17 from '../Images/gallery/image21.JPG';
import img18 from '../Images/gallery/image22.JPG';
import img19 from '../Images/gallery/image23.JPG';
import img20 from '../Images/gallery/image24.JPG';
import img21 from '../Images/gallery/image25.JPG';
import img22 from '../Images/gallery/image26.JPG';
import img23 from '../Images/gallery/image27.JPG';
import img24 from '../Images/gallery/image28.JPG';

import '../Extensions/fontawesome/css/all.min.css';
import '../Extensions/fontawesome/webfonts/fa-regular-400.ttf';

const ImgGalleryContent = () => {
  useEffect(() => {
    AOS.init();
  }, [])
    return(
        <section id="image-gallery" >
          <div class="container">
            <h3>Bildergalerie</h3>
            <hr/>
            <div class="image-gallery">
              <div class="image-gallery-container">
                <img src={img1} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img2} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img3} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div> 
              <div class="image-gallery-container">
                <img src={img4} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img5} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img6} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div> 
              <div class="image-gallery-container">
                <img src={img7} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img8} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img9} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div>   
              <div class="image-gallery-container">
                <img src={img10} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img11} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img12} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div>
              <div class="image-gallery-container">
                <img src={img13} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img14} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img15} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div>
              <div class="image-gallery-container">
                <img src={img16} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img17} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img18} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div>
              <div class="image-gallery-container">
                <img src={img19} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img20} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img21} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div>
              <div class="image-gallery-container">
                <img src={img22} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img23} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img24} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div>
            </div>
          </div>
        </section>
    )
}

export default ImgGalleryContent;